.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
}

.link {
  grid-column: 1;
  grid-row: 1;
  z-index: var(--z-layer-1);
}

.background {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr;
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
  height: inherit;
  position: relative;
  align-items: center;
}

.left {
  display: flex;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  width: 100%;
  height: 50vh;
}

.left span {
  height: 100% !important;
  width: 100% !important;
}

.left a {
  width: 100%;
  cursor: pointer;
}

.right {
  display: flex;
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  height: 50vh;
  width: 100%;
}

.right span {
  height: 100% !important;
  width: 100% !important;
}

.right a {
  width: 100%;
  cursor: pointer;
}

.foreground {
  grid-column: 1 / 1;
  grid-row: 1 / 2;
  display: grid;
  min-height: 100vh;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
  text-align: center;
  position: relative;
}

.titleContainer {
  place-items: center;
  grid-row: 1 / span 2;
  grid-column: 1 / span 1;
  place-self: center center;
  z-index: var(--z-layer-2);
}

.title {
  color: var(--palette-text-dark);
  font-family: var(--font-accent);
  font-size: clamp(2.5rem, 8vw, 6rem);
  letter-spacing: 0.01em;
  line-height: var(--line-height-heading);
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.subtitle {
  color: var(--palette-text-dark);
  margin-top: 1em;
  display: none;
  font-family: var(--font-sans);
  font-size: clamp(1rem, 2vw, 1.5rem);
  letter-spacing: 0.01em;
  line-height: var(--line-height-copy);
  text-transform: none;
  max-width: 30em;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.linkLeft {
  display: flex;
  grid-column: 1 / span 1;
  grid-row: 1 / span 1;
  place-self: center center;
  margin-bottom: 0;
  color: #ffffff;
}

.fullLeft {
  grid-column: 1 / span 2;
  grid-row: 2 / span 1;
}

.linkLeft a {
  place-self: center;
  font-size: clamp(1rem, 2vw, 1.25rem);
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

.linkRight {
  display: flex;
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  place-self: center center;
  margin-bottom: 0;
  color: #ffffff;
}

.linkRight a {
  place-self: center;
  font-size: clamp(1rem, 2vw, 1.25rem);
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

@media (min-width: 35em) {
  .background {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .left {
    height: 100%;
  }
  .right {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    height: 100%;
  }
  .foreground {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100vh;
  }
  .titleContainer {
    grid-column: 1 / span 2;
    grid-row: 2 / span 1;
  }
  .subtitle {
    display: inline-block;
  }
  .linkLeft {
    grid-row: 3 / span 1;
  }

  .linkRight {
    grid-column: 2 / span 1;
    grid-row: 3 / span 1;
  }

  .fullRight {
    grid-column: 1 / span 2;
  }
}

@media (min-width: 60em) {
  .background {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }
  .left {
    height: 100%;
  }
  .right {
    grid-column: 2 / span 1;
    grid-row: 1 / span 1;
    height: 100%;
  }
  .foreground {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    height: 100vh;
  }
  .linkLeft {
    grid-row: 3 / span 1;
  }

  .linkRight {
    grid-column: 2 / span 1;
    grid-row: 3 / span 1;
  }
}
