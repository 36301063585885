.header {
  text-align: center;
  padding: 2rem;
  font-size: 2.5rem;
  background: var(--color-white);
}

.container {
  padding: 0px;
  color: var(--color-darker-gray);
  display: grid;
  grid-template-columns: 1fr;
}

@media (min-width: 35em) {
  .container {
    margin: var(--spacing-6);
    grid-template-columns: 1fr 1fr;
  }
  .header {
    padding: 3rem;
    font-size: 4rem;
  }
}

@media (min-width: 60em) {
  .container {
    grid-template-columns: 1fr 1fr 1fr;
  }
}
