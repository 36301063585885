.container {
  display: grid;
}

.link {
  grid-column: 1;
  grid-row: 1;
  z-index: var(--z-layer-1);
}

.left {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  grid-template-rows: 400px 400px;
  grid-template-columns: 1fr;
  align-items: stretch;
  background-color: #bfb6b6;
}

.left span {
  width: 100% !important;
  height: 100% !important;
  object-fit: cover !important;
}

.right {
  display: grid;
  min-height: 100%;
  grid-template-rows: 1fr auto;
  text-align: center;
  color: var(--palette-text-dark);
}

.right a {
  place-self: end center;
  grid-row: 2;
  margin-bottom: 3em;
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

.title {
  font-family: var(--font-accent);
  font-size: var(--text-display);
  letter-spacing: 0.01em;
  line-height: var(--line-height-heading);
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  grid-row: 1;
  place-self: center stretch;
}

@media (min-width: 35em) {
  .left {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 600px;
  }
}
