.container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  place-items: center center;
}

.link {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  z-index: var(--z-layer-1);
  font-size: clamp(1rem, 2vw, 1.25rem);
}

.wrapper {
  grid-column: 1 / span 2;
  grid-row: 1 / span 2;
  position: relative;
  width: 100%;
  height: calc(100vh - 4.5rem);
  height: calc(100svh - 4.5rem);
}

.wrapper span {
  height: calc(100vh - 4.5rem);
  height: calc(100svh - 4.5rem);
}

.left {
  display: flex;
  grid-column: 1 / span 1;
  grid-row: 2 / span 1;
  justify-self: start;
  align-self: flex-end;
  padding-bottom: 2rem;
  padding-left: 1rem;
  color: var(--palette-text-dark);
}

.right {
  display: flex;
  grid-column: 2 / span 1;
  grid-row: 2 / span 1;
  justify-self: flex-end;
  align-self: flex-end;
  padding-bottom: 2rem;
  padding-right: 1rem;
  color: var(--palette-text-dark);
  justify-content: flex-end;
}

.right a {
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}
