.container {
  overflow: hidden;
  position: relative;
  border-top: solid 1px var(--color-light-gray);
  border-bottom: solid 1px var(--color-light-gray);
}
.innerContainer {
  width: 100%;
  display: flex;
  gap: 1px;
  background: var(--color-light-gray);
  flex-direction: row;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.innerContainer::-webkit-scrollbar {
  display: none;
}

.spacer {
  padding: 1rem;
  background: var(--color-white);
}

@media (min-width: 35em) {
  .spacer {
    padding: 2rem;
  }
}

.cell {
  width: calc(100vw - 4rem);
  scroll-snap-align: center;
  flex-shrink: 0;
  padding: 0;
}


@media (min-width: 35em) {
  .spacer {
    padding: 2rem;
  }
}

.cell img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: none;
}

.arrow {
  display: none;
}

@media (min-width: 30em) {
  .cell {
    width: calc(((100vw - 8rem) / 2) - 1.5px);
  }
  .arrow {
    display: block;
    position: absolute;
    cursor: pointer;
    top: 50%;
    padding: 1rem;
    z-index: 1;
    transition: transform 0.1s ease-in-out;
    border-radius: 100%;
    border: none;
    padding: var(--spacing-1);
  }

  .arrowLeft {
    left: 0rem;
    transform: translate(-100%, -50%);
  }

  .arrowRight {
    right: 0rem;
    transform: translate(100%, -50%);
  }
  .container:hover .arrow {
    transform: translate(0%, -50%);
  }

  .visible {
    visibility: all;
  }
  .notVisible {
    visibility: hidden;
  }
}

@media (min-width: 45em) {
  .cell {
    width: calc(((100vw - 8rem) / 3) - 1.5px);
  }
}

@media (min-width: 60em) {
  .cell {
    width: calc(((100vw - 8rem) / 4) - 1.5px);
  }
}
