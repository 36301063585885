.container {
  display: grid;
  gap: var(--spacing-8);
  padding: var(--spacing-8);
  grid-auto-flow: row;
  justify-content: space-around;
  align-items: end;
}

.heading {
  font-size: var(--text-display);
  font-size: clamp(2rem, 5vw, 4em);
  font-family: Reckless;
  line-height: 1.1;
  max-width: 15em;
  margin-bottom: -0.15em;
}

.form {
  min-width: 18rem;
  max-width: 40rem;
  display: grid;
  gap: var(--spacing-4);
}

.disclaimer a {
  text-decoration: underline;
  text-decoration-color: var(--palette-text);
  text-decoration-thickness: 0.125em;
  text-underline-offset: 1.5px;
}

.thankyou {
  overflow: hidden;
  max-height: 0;
  transition: opacity 0.15s ease 0.15s, max-height 0.3s ease;
  font-weight: 200;
  text-align: center;
  margin-top: 1rem;
  opacity: 0;
}

.show {
  opacity: 1;
  max-height: 11em;
}

@media (min-width: 35em) {
  .container {
    padding: var(--spacing-24) var(--spacing-8) var(--spacing-16);
  }
}

@media (min-width: 60em) {
  .container {
    grid-auto-flow: column;
  }
}
