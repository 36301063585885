.card {
  padding: var(--spacing-2);
  background: var(--color-white);
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

@media (min-width: 30em) {
  .card {
    padding: var(--spacing-4);
  }
}

@media (min-width: 45em) {
  .card {
    padding: var(--spacing-6);
  }
}

.image > span {
  height: 0 !important;
  padding-bottom: 100% !important;
}

.footer {
  flex-grow: 1;
  padding: var(--spacing-2);
  padding-top: var(--spacing-4);
  color: var(--color-darker-gray);
  font-family: var(--font-sans);
}

.footer h3 {
  margin-bottom: var(--spacing-3);
  font-size: var(--text-heading);
  line-height: var(--line-height-heading);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.footer p {
  margin-bottom: var(--spacing-3);
  font-size: var(--text-copy);
  line-height: var(--line-height-copy);
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.footer small {
  color: var(--color-gray);
  font-size: var(--text-fine);
  text-transform: uppercase;
  line-height: var(--line-height-solid);
  letter-spacing: 0.05em;
}

@supports (aspect-ratio: 1 / 1) {
  .image > span {
    aspect-ratio: 1 / 1;
  }
}
