.error {
	margin-top: 4rem;
	display: grid;
	justify-items: center;
	align-items: center;
	padding: var(--spacing-8);
	height: 50vh;
}

.error h1 {
	font-family: Sohne Breit;
	font-size: clamp(2.5rem, 10vw, 4rem);
	letter-spacing: 0.01em;
	line-height: 1.1;
	text-transform: none;
	font-weight: 400;
	margin: 0;
	-webkit-font-smoothing: antialiased;
	text-align: center;
}