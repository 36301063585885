.box {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 3rem 1rem;
}

@media (min-width: 35em) {
  .box {
    padding: 3rem 1rem;
  }
}

@media (min-width: 60em) {
  .box {
    padding: 4rem 1rem;
  }
}
