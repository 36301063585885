.container {
  display: grid;
  gap: 1rem;
  place-items: stretch;
  place-content: stretch;
  margin: 1rem;
  grid-template-columns: repeat(4, 1fr);
}

.full {
  gap: 0;
  margin: 0;
}

@media (min-width: 35em) {
  .container {
    grid-template-columns: repeat(6, 1fr);
    margin: 2rem;
  }

  .full {
    margin: 0;
  }

  .fullHeight {
    height: 100vh;
  }

  .eightyHeight {
    height: 80vh;
  }

  .autoHeight {
    height: auto;
  }

  .halfHeight {
    height: 50vh;
  }
}

@media (min-width: 60em) {
  .container {
    grid-template-columns: repeat(12, 1fr);
  }
}
