.wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(auto, 1fr));
  padding: 24px 30px 30px 24px;
  gap: clamp(2rem, 8vw, 5rem);
}

.wrapper h4 {
  font-family: Reckless;
}

.withBackground {
  background-color: "#EDEBE7";
}

.heading {
  grid-row: 1;
  grid-column: 1;
}

.noSubheading {
  grid-row: 1;
  grid-column: 1;
}
.subHeading {
  grid-row: 2;
  grid-column: 1;
}
.noHeading {
  grid-row: 1;
}
.noChildren {
  grid-column: 1;
}
.cta {
  grid-column: 1;
  justify-self: start;
  align-self: end;
}
.ctaAlone {
  grid-column: 1;
  justify-self: start;
  align-self: end;
}

.links {
  display: grid;
  gap: 1.5rem;
  justify-content: end;
  grid-auto-flow: column;
}

.links a {
  white-space: nowrap;
}

@media (min-width: 35em) {
  .wrapper {
    padding: 47px 61px 58px 48px;
  }

  .heading {
    grid-column: 1 / span 2;
  }

  .cta {
    grid-column: 2;
    justify-self: end;
  }
}
