.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
}

.link {
  grid-column: 1;
  grid-row: 1;
  z-index: var(--z-layer-1);
}

.background {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
  position: relative;
  align-items: center;
}

.leftImageWrapper {
  display: flex;
  grid-column: 1 / span 1;
  height: 100%;
  width: 100%;
}

.leftImageWrapper span {
  height: 100% !important;
  width: 100% !important;
}

.rightImageWrapper {
  display: none;
  justify-content: center;
  align-items: center;
  grid-column: 2 / span 1;
  height: 100%;
  width: 100%;
  background-color: #bfb6b6;
}

.rightImageWrapper span {
  object-fit: cover !important;
  height: 400px !important;
  width: 300px !important;
  place-self: center center !important;
}

.foreground {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  min-height: 100vh;
  grid-template-rows: 1fr 4fr 1em;
  grid-template-columns: 1fr;
  text-align: center;
  position: relative;
  color: var(--palette-text-dark);
}

.foreground a {
  place-self: center center;
  grid-row: 3 / span 1;
  margin-bottom: 3em;
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

.title {
  font-family: var(--font-accent);
  font-size: var(--text-display);
  letter-spacing: 0.01em;
  line-height: var(--line-height-heading);
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  grid-row: 2 / span 1;
  place-self: center center;
}

@media (min-width: 35em) {
  .background {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .rightImageWrapper {
    display: flex;
  }

  .foreground {
    grid-template-columns: 1fr 1fr;
  }
}
