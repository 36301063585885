.wrapper {
  display: grid;
  grid-auto-flow: row;
  place-items: center;
  text-align: center;
  margin: 2rem 0;
}

.wrapper h2 {
  font-size: 2.25rem;
  margin: 1rem 0;
  text-align: center;
  font-weight: 400;
}

.title {
  font-size: 1rem;
  margin-bottom: 2rem;
  color: #191919;
  border-bottom: solid 1px #191919;
  text-decoration: none;
}

.list {
  margin: 0;
  padding: 0;
}

.listItem a {
  font-size: 2.5rem;
  font-weight: 200;
}

@media (min-width: 35em) {
  .wrapper {
    margin: 4rem 0;
  }
  .wrapper h2 {
    font-size: 4rem;
  }
}
