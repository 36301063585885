.unorderedList {
  list-style: disc outside none;
  padding-left: 1rem;
}

.orderedList {
  list-style: decimal outside none;
  padding-left: 1rem;
}

.listItem {
  font-family: Sohne, system-ui, -apple-system, sans-serif;
  font-size: clamp(1rem, 2vw, 1.25rem);
  letter-spacing: 0.01em;
  line-height: 1.4;
  text-transform: none;
  max-width: 30em;
  font-weight: normal;
  margin: 0.5em;
}

.listItem:first-of-type {
  margin-top: 0;
}

.listItem:last-of-type {
  margin-bottom: 0;
}

.center {
  text-align: center;
  margin: 0.5em auto;
}

.blockWrapper div {
  padding: 0 !important;
}
.blockWrapper h1,
.blockWrapper h2,
.blockWrapper h3,
.blockWrapper h4,
.blockWrapper h5,
.blockWrapper p {
  margin-bottom: 0.75em;
}
.blockWrapper h1:last-child,
.blockWrapper h2:last-child,
.blockWrapper h3:last-child,
.blockWrapper h4:last-child,
.blockWrapper h5:last-child,
.blockWrapper p:last-child {
  margin-bottom: 0;
}

.blockWrapper a {
  text-decoration: underline;
}

.blockWrapper blockquote {
  font-family: Reckless;
  font-size: 2rem;
  line-height: 1.1;
}

@media (min-width: 35em) {
  .listItem {
    margin: 1em;
  }

  .center {
    margin: 1em auto;
  }
}
