.container {
  height: inherit;
  box-sizing: border-box;
  display: flex;
  place-content: stretch;
  place-items: stretch;
  background: var(--palette-background);
  grid-column-end: span var(--grid-end);
}

.container a {
  width: auto;
}

.container a:only-child {
  width: fit-content;
}

.img span {
  object-fit: scale-down !important;
  max-width: 100% !important;
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
  display: block !important;
}

.container video {
  object-fit: scale-down;
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  display: block;
}

.hasTextOrVideo {
  grid-column-end: span 4;
}

.text {
  place-content: center;
  place-items: center;
}

.text div {
  padding: clamp(1rem, 2vw, 3rem);
}

.text div > *:not(div) {
  max-width: 30em;
}

.full img {
  object-fit: cover;
}

.full video {
  object-fit: cover;
}

.paddingNone {
  padding: 0;
}

.paddingXS {
  padding: 0.25rem;
}

.paddingS {
  padding: 0.5rem;
}

.paddingM {
  padding: 1rem;
}

.paddingL {
  padding: 2rem;
}

.paddingXL {
  padding: 4rem;
}
.beigeBackground {
  background: var(--color-off-white);
}

@media (max-width: 35em) {
  .hideOnSmall {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}
@media (min-width: 35em) {
  .container {
    grid-column-end: span var(--grid-end-medium);
  }

  .paddingXS {
    padding: 0.5rem;
  }

  .paddingS {
    padding: 1rem;
  }

  .paddingM {
    padding: 1.5rem;
  }

  .paddingL {
    padding: 2.5rem;
  }

  .paddingXL {
    padding: 4rem;
  }
}

@media (min-width: 35em) and (max-width: 60em) {
  .hideOnMedium {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }
}

@media (min-width: 60em) {
  .container {
    grid-column-end: span var(--grid-end-large);
  }
  .hideOnLarge {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    white-space: nowrap;
    width: 1px;
  }

  .paddingXS {
    padding: 1rem;
  }

  .paddingS {
    padding: 2rem;
  }

  .paddingM {
    padding: 3rem;
  }

  .paddingL {
    padding: 5rem;
  }

  .paddingXL {
    padding: 8rem;
  }
}
