.videoButton {
  padding: 1em;
}
.videoButton svg {
  color: inherit;
}

.videoWrapper {
  position: relative;
  overflow: hidden;
  color: white;
}

.videoWrapper video {
  z-index: 0;
  width: 100%;
  height: auto;
}
/* @media (min-width: 35em) {
  .videoWrapper video {
    max-width: none;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
  }
} */

.videoControls {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 4;
  display: grid;
  pointer-events: none;
  grid-auto-flow: column;
  justify-content: space-between;
  width: 100%;
}

.videoControls > button {
  pointer-events: auto;
}