.container {
  padding: var(--spacing-8) var(--spacing-4);
}

.linksWrapper {
  display: grid;
  grid-template-columns: auto auto auto;
  grid-gap: var(--spacing-4);
  max-width: fit-content;
  margin: 0 auto;
}

.linksWrapper a {
  font-size: var(--text-copy);
  line-height: var(--line-height-heading);
  text-align: center;
}

.linksWrapper a:hover {
  color: var(--color-dark-gray);
}

.title {
  font-size: 1.5rem;
  line-height: var(--line-height-heading);
  text-align: center;
  padding-bottom: var(--spacing-8);
}

.description {
  display: grid;
  gap: var(--spacing-4);
  box-shadow: 0 1px 0 0 var(--color-light-gray) inset;
  padding: 32px 40px 32px 16px;
}

.description h4 {
  font-size: var(--text-heading);
}

.description p {
  font-size: var(--text-copy);
}

@media (min-width: 45em) {
  .description {
    padding: 32px 40px 32px 64px;
  }

  .description p {
    font-size: var(--text-heading);
  }

  .container {
    width: 100%;
  }

  .linksWrapper {
    justify-self: center;
    column-gap: var(--spacing-16);
  }

  .linksWrapper a {
    font-size: var(--text-heading);
  }

  .title {
    font-size: 2rem;
  }
}
