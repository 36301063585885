.wrapper {
  display: grid;
  gap: 1px;
  background: var(--color-light-gray);
  grid-auto-flow: row;
  grid-template-columns: repeat(2, 1fr);
  padding-top: 1px;
  padding-bottom: 1px;
  place-content: start;
  place-items: start;
}

.wrapper > a {
  width: 100%;
}

.header {
  text-align: center;
  padding: 2rem;
  font-size: var(--text-heading);
  background: var(--color-white);
}

@media (min-width: 35em) {
  .wrapper {
    grid-template-columns: repeat(3, 1fr);
  }
  .header {
    padding: 3rem;
  }
}

@media (min-width: 60em) {
  .wrapper {
    grid-template-columns: repeat(4, 1fr);
  }
}
