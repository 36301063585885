.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
}

.link {
  grid-column: 1;
  grid-row: 1;
  z-index: var(--z-layer-1);
}

.background {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(8, 1fr);
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
  height: inherit;
  position: relative;
  padding: 2.75em 1em 1em;
  gap: 1rem;
}

.imageBack {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.imageBack span {
  object-fit: cover !important;
  object-position: 50% 50% !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
}

.imageFront {
  display: block;
  grid-column: 3 / span 8;
  grid-row: 2 / span 6;
  position: relative;
  background-repeat: no-repeat;
  background-position: center center;
}

.imageFront span {
  object-fit: cover !important;
  object-position: top !important;
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  height: 100% !important;
  width: 100% !important;
  max-width: 100% !important;
}

.foreground {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  min-height: 100vh;
  grid-template-rows: 6em 1fr 6em;
  padding: 2.75em 1em 1em;
  text-align: center;
  position: relative;
  color: #ffffff;
}

.foreground a {
  place-self: center center;
  grid-row: 3 / span 1;
  margin-bottom: 3em;
  place-self: center;
  font-size: clamp(0.875rem, 2vw, 1.25rem);
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

.title {
  color: var(--palette-text-dark);
  font-family: var(--font-accent);
  font-size: var(--text-display);
  font-size: clamp(3.5rem, 8vw, 10rem);
  letter-spacing: 0.01em;
  line-height: var(--line-height-heading);
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  place-self: center center;
  grid-row: 2 / span 1;
}
