.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
}

.link {
  grid-column: 1;
  grid-row: 1;
  z-index: 10;
}

.background {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 100vh;
  position: relative;
  align-items: center;
}

.backgroundImage {
  grid-column: 1 / span 1;
  height: 100%;
  width: 100%;
}

.backgroundImage span {
  height: 100vh !important;
  width: 100% !important;
}

.foreground {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  min-height: 100vh;
  grid-template-rows: 1fr 4fr 1fr;
  grid-template-columns: 1fr;
  text-align: center;
  position: relative;
  color: #ffffff;
}

.foreground a {
  place-self: center center;
  grid-row: 3 / span 1;
  margin-bottom: 3em;
  place-self: center;
  font-size: clamp(1rem, 2vw, 1.25rem);
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

.title {
  color: var(--palette-text-dark);
  font-family: var(--font-accent);
  font-size: var(--text-display);
  letter-spacing: 0.01em;
  line-height: var(--line-height-heading);
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  place-self: center center;
  grid-row: 2 / span 1;
}
