.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 100vh;
}

.link {
  grid-column: 1;
  grid-row: 1;
  z-index: var(--z-layer-1);
}

.background {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: 100vh;
  position: relative;
  align-items: center;
  background-color: #bfb6b6;
}

.left {
  display: none;
  grid-column: 1 / span 1;
  height: 100%;
  width: 100%;
}

.left span {
  height: 100% !important;
  width: 100% !important;
}

.middle {
  display: flex;
  justify-content: center;
  grid-column: 1 / span 1;
}

.middle span {
  object-fit: cover !important;
  height: 313px !important;
  width: 213px !important;
}

.right {
  display: none;
  grid-column: 3 / span 1;
  height: 100%;
  width: 100%;
}

.right span {
  height: 100% !important;
  width: 100% !important;
}

.foreground {
  grid-column: 1 / 1;
  grid-row: 1 / 2;
  display: grid;
  min-height: 100vh;
  grid-template-rows: 6em 1fr 6em;
  text-align: center;
  position: relative;
  color: var(--palette-text-dark);
}

.foreground a {
  place-self: center center;
  grid-row: 3 / span 1;
  margin-bottom: 3em;
  font-size: clamp(1rem, 2vw, 1.25rem);
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

.title {
  font-family: var(--font-accent);
  font-size: var(--text-display);
  letter-spacing: 0.01em;
  line-height: var(--line-height-heading);
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  grid-row: 2 / span 1;
  place-self: center center;
}

@media (min-width: 35em) {
  .background {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .left {
    display: flex;
  }

  .middle {
    grid-column: 2 / span 1;
  }

  .right {
    display: none;
  }
}

@media (min-width: 60em) {
  .background {
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr;
  }

  .right {
    display: flex;
  }
}
