.container {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
}

.link {
  grid-column: 1;
  grid-row: 1;
  z-index: var(--z-layer-1);
}

.background {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(2, 1fr);
  min-height: calc(100vh - 4.5rem);
  min-height: calc(100svh - 4.5rem);
  height: inherit;
  position: relative;
  padding: 0;
  gap: 0;
}

.imgWrapper {
  grid-column: 1 / span 12;
  grid-row: 1 / span 2;
  position: relative;
}

.imgWrapper span {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  object-fit: cover !important;
  height: 100% !important;
  width: 100% !important;
  object-position: 50% 50% !important;
}

.foreground {
  grid-column: 1 / 1;
  grid-row: 1 / 1;
  display: grid;
  min-height: 100vh;
  height: inherit;
  grid-template-rows: 1fr 1fr 1fr;
  padding: 5em 1em 5em;
  position: relative;
  color: var(--palette-text-dark);
  text-align: center;
}

.title {
  font-family: var(--font-accent);
  font-size: var(--text-display);
  font-size: clamp(2.5rem, 8vw, 6rem);
  letter-spacing: 0.01em;
  line-height: var(--line-height-heading);
  text-transform: none;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  grid-row: 2 / span 1;
  place-self: center center;
  text-align: center;
}

.subheading {
  grid-row: 3 / span 1;
  place-self: end center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  justify-self: stretch;
  align-self: stretch;
}

.subheading a {
  font-size: 1rem;
  font-size: clamp(0.875rem, 2vw, 1.25rem);
  box-shadow: 0 0.125em 0 -0.05em currentcolor;
}

.subtitle {
  font-size: 1rem;
  font-size: clamp(1rem, 2vw, 1.5rem);
  text-align: center;
  max-width: 30em;
}
